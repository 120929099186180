/**
 * encourse-pisa Main App Service
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Injectable } from '@angular/core';
import { ApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';



@Injectable()
export class PerformancesService {

  constructor(
    protected api: ApiClientService) {
  }

  /**
   */
  public apiPerformancesStartExamTestGet$(): Observable<any> {
    return this.api.get(`/api/Performances/StartExamTest`, undefined, true);
  }
}
