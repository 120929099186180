/**
 * encourse-pisa Main App Service
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Injectable } from '@angular/core';
import { ApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';

import { ChangePasswordDTO } from '../../model/changePasswordDTO';
import { ChangeQuestionDTO } from '../../model/changeQuestionDTO';
import { FileResult } from '../../model/fileResult';
import { PasswordDTO } from '../../model/passwordDTO';
import { QuestionAnswerUsrDTO } from '../../model/questionAnswerUsrDTO';
import { QuestionUsrDTO } from '../../model/questionUsrDTO';
import { RegisterDTO } from '../../model/registerDTO';
import { ResetPasswordDTO } from '../../model/resetPasswordDTO';
import { ResetPasswordQuestionDTO } from '../../model/resetPasswordQuestionDTO';
import { UserManageListDTO } from '../../model/userManageListDTO';


@Injectable()
export class UsersService {

  constructor(
    protected api: ApiClientService) {
  }

  /**
   * [Authorize] type: apiKey
   */
  public apiUsersChangePasswordPut$(input?: ChangePasswordDTO): Observable<any> {
    return this.api.put(`/api/Users/ChangePassword`, input, undefined);
  }
  /**
   */
  public apiUsersCreateGeneralUserPost$(input?: RegisterDTO): Observable<UserManageListDTO> {
    return this.api.post(`/api/Users/CreateGeneralUser`, input, undefined, true);
  }
  /**
   */
  public apiUsersForgotPasswordPost$(input?: ResetPasswordQuestionDTO): Observable<ResetPasswordDTO> {
    return this.api.post(`/api/Users/ForgotPassword`, input, undefined, true);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiUsersMyQuestionsPost$(input?: PasswordDTO): Observable<Array<QuestionAnswerUsrDTO>> {
    return this.api.post(`/api/Users/MyQuestions`, input, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiUsersMyQuestionsPut$(input?: ChangeQuestionDTO): Observable<ChangeQuestionDTO> {
    return this.api.put(`/api/Users/MyQuestions`, input, undefined);
  }
  /**
   */
  public apiUsersOnlineGet$(): Observable<any> {
    return this.api.get(`/api/Users/Online`, undefined, true);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiUsersOnlinePost$(): Observable<any> {
    return this.api.post(`/api/Users/Online`, undefined, undefined);
  }
  /**
   */
  public apiUsersQuestionsDropdownListGet$(): Observable<Array<QuestionUsrDTO>> {
    return this.api.get(`/api/Users/Questions/DropdownList`, undefined, true);
  }
  /**
   */
  public apiUsersRandomQuestionsGet$(idcode?: string): Observable<ResetPasswordQuestionDTO> {
    return this.api.get(`/api/Users/RandomQuestions`, { idcode }, true);
  }
  /**
   */
  public apiUsersRegisterPost$(input?: RegisterDTO): Observable<any> {
    return this.api.post(`/api/Users/Register`, input, undefined, true);
  }
  /**
   */
  public apiUsersResetPasswordPost$(input?: ResetPasswordDTO): Observable<any> {
    return this.api.post(`/api/Users/ResetPassword`, input, undefined, true);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiUsersUploadProfilePicturePost$(): Observable<FileResult> {
    return this.api.post(`/api/Users/UploadProfilePicture`, undefined, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiUsersUserProfileGet$(): Observable<UserManageListDTO> {
    return this.api.get(`/api/Users/UserProfile`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiUsersUserProfilePut$(input?: UserManageListDTO): Observable<UserManageListDTO> {
    return this.api.put(`/api/Users/UserProfile`, input, undefined);
  }
}
