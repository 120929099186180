import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'analysisPerQuestionDetails'
})
export class AnalysisPerQuestionDetailsPipe implements PipeTransform {
  transform(value: any, type: string): any {
    if (type === 'ผลการตรวจให้คะแนนรายบุคคล') {
      // return value === 'true' ? '/' : 'X';
      return value;
    } else {
      return value;
    }
  }
}
