import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({ name: 'oneDecimalPlace' })
export class OneDecimalPlacePipe extends DecimalPipe implements PipeTransform {
  constructor() {
    super('en-US');
  }
  public transform(value): any {
    return super.transform(value, '1.1-1');
  }
}
