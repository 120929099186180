import { NgModule, ModuleWithProviders } from '@angular/core';

import { BookingsService } from './api/bookings.service';
import { DistrictsService } from './api/districts.service';
import { RegisterExamsService } from './api/exams.service';
import { LoaderVerifyService } from './api/loaderVerify.service';
import { PerformancesService } from './api/performances.service';
import { ProvincesService } from './api/provinces.service';
import { ReportsService } from './api/reports.service';
import { SchoolsService } from './api/schools.service';
import { TokenService } from './api/token.service';
import { UsersService } from './api/users.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    BookingsService,
    DistrictsService,
    RegisterExamsService,
    LoaderVerifyService,
    PerformancesService,
    ProvincesService,
    ReportsService,
    SchoolsService,
    TokenService,
    UsersService
  ]
})
export class RegisterApiModule { }
