/**
 * encourse-pisa Main App Service
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ShortAnswerDropdownDTO } from './shortAnswerDropdownDTO';

export interface ShortAnswerTagDTO {
    id: string;
    tag: number;
    answer: string;
    type: ShortAnswerTagDTO.TypeEnum;
    shortAnswerDropdowns: Array<ShortAnswerDropdownDTO>;
}
export namespace ShortAnswerTagDTO {
    export type TypeEnum = 'Text' | 'Number' | 'Dropdown' | 'Reason' | 'Equation' | 'EquationReason';
    export const TypeEnum = {
        Text: 'Text' as TypeEnum,
        Number: 'Number' as TypeEnum,
        Dropdown: 'Dropdown' as TypeEnum,
        Reason: 'Reason' as TypeEnum,
        Equation: 'Equation' as TypeEnum,
        EquationReason: 'EquationReason' as TypeEnum
    };
}
