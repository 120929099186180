<div class="_container">
  <app-header [showLoginAndProfile]="true"></app-header>
  <div class="section is-short">
    <div class="notification is-secondary">
      การตรวจข้อสอบถูกปรับเปลี่ยนรูปแบบการตรวจ ทำให้ไม่สามารถทราบผลได้ในทันทีหลังสอบเสร็จสิ้นในแต่ละครั้ง
ข้อสอบประเภทเลือกตอบสามารถกลับมาตรวจสอบผลได้ในวันถัดไป
    </div>
  </div>
  <app-navigation></app-navigation>
  <div class="_content">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>