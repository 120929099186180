/**
 * encourse-pisa Main App Service
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Injectable } from '@angular/core';
import { ApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';

import { MasterProvinceDTO } from '../../model/masterProvinceDTO';


@Injectable()
export class ProvincesService {

  constructor(
    protected api: ApiClientService) {
  }

  /**
   */
  public apiProvincesGet$(name?: string, schoolId?: string, districtId?: string, provincesId?: string): Observable<Array<MasterProvinceDTO>> {
    return this.api.get(`/api/Provinces`, { name, schoolId, districtId, provincesId }, true);
  }
}
