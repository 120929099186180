import { Component, OnInit, Input } from '@angular/core';

export interface Step {
  step: number;
  stepTitle: string;
  isActive: boolean;
  pageTitle?: string;
}

@Component({
  selector: 'lib-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {

  @Input() steps: Step[];

  constructor() { }

  ngOnInit() {
  }

}
