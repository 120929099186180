<section class="section is-fit">
  <div class="container">
    <div class="_banner">
      <h1 class="_title-banner">ระบบข้อสอบออนไลน์ PISA</h1>
      <img
        class="_image-banner"
        src="assets/image/banner-balloon.jpg"
      >
    </div>
    <img
      class="_logo"
      src="assets/image/logo.png"
    >

    <nav
      class="navbar _navigation"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navbar-menu">
        <div class="navbar-start">
          <a
            class="navbar-item"
            [routerLink]="['/']"
          >
            หน้าแรก
          </a>
          <a
            class="navbar-item"
            [routerLink]="['/exam']"
          >
            ทำข้อสอบ
          </a>
          <!-- <a class="navbar-item">
            วิธีการใช้งาน
          </a>
          <a class="navbar-item">
            เกี่ยวกับระบบ
          </a>
          <a class="navbar-item">
            ติดต่อเรา
          </a> -->
        </div>
      </div>
    </nav>

  </div>
</section>