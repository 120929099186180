/**
 * encourse-pisa Main App Service
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Injectable } from '@angular/core';
import { ApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';

import { DragDropQuestionDTO } from '../../model/dragDropQuestionDTO';
import { ExamContentDTO } from '../../model/examContentDTO';
import { ExamDTO } from '../../model/examDTO';
import { ExamResultDTO } from '../../model/examResultDTO';
import { ExamTestQuestionMapDTO } from '../../model/examTestQuestionMapDTO';
import { HtmlContentDTO } from '../../model/htmlContentDTO';
import { MultipleAnswerQuestionDTO } from '../../model/multipleAnswerQuestionDTO';
import { MultipleChoiceQuestionDTO } from '../../model/multipleChoiceQuestionDTO';
import { MultipleChoiceReasonQuestionDTO } from '../../model/multipleChoiceReasonQuestionDTO';
import { QuestionDTO } from '../../model/questionDTO';
import { ShortAnswerQuestionDTO } from '../../model/shortAnswerQuestionDTO';
import { SubjectiveQuestionDTO } from '../../model/subjectiveQuestionDTO';
import { TabledMultipleAnswerQuestionDTO } from '../../model/tabledMultipleAnswerQuestionDTO';
import { TabledMultipleQuestionDTO } from '../../model/tabledMultipleQuestionDTO';
import { UpdateQuestionCode } from '../../model/updateQuestionCode';
import { UpdateTimeUsedDTO } from '../../model/updateTimeUsedDTO';
import { UpdateTimeUsedParam } from '../../model/updateTimeUsedParam';


@Injectable()
export class RegisterExamsService {

  constructor(
    protected api: ApiClientService) {
  }

  /**
   * [Authorize] type: apiKey
   */
  public apiExamsExamHistoryGet$(page?: number, pageSize?: number, sortBy?: string, ascending?: boolean, year?: number, month?: number, day?: number, headerResponse?: HeaderResponse): Observable<Array<ExamDTO>> {
    return this.api.get(`/api/Exams/ExamHistory`, { page, pageSize, sortBy, ascending, year, month, day }, false, headerResponse);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdExamTestResultByExamTestResultIdQuestionsByQuestionIdGet$(id: string, examTestResultId: string, questionId: string): Observable<QuestionDTO> {
    return this.api.get(`/api/Exams/${encodeURIComponent(String(id))}/ExamTestResult/${encodeURIComponent(String(examTestResultId))}/Questions/${encodeURIComponent(String(questionId))}`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsExamResultsByExamTestResultIdGet$(examTestResultId: string, headerResponse?: HeaderResponse): Observable<ExamResultDTO> {
    return this.api.get(`/api/Exams/ExamResults/${encodeURIComponent(String(examTestResultId))}`, undefined, false, headerResponse);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsListGet$(page?: number, pageSize?: number, sortBy?: string, ascending?: boolean, q?: string, headerResponse?: HeaderResponse): Observable<Array<ExamDTO>> {
    return this.api.get(`/api/Exams/List`, { page, pageSize, sortBy, ascending, q }, false, headerResponse);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsUnfinishedExamGet$(examEndpoint?: string, headerResponse?: HeaderResponse): Observable<ExamContentDTO> {
    return this.api.get(`/api/Exams/UnfinishedExam`, { examEndpoint }, false, headerResponse);
  }
}
