import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getClassByValue'
})
export class GetClassByValuePipe implements PipeTransform {
    transform(value: any, type: string): any {
        if (type === 'สาระการเรียนรู้' || type === 'สาระการเรียนรู้ย่อย' || type === 'subject') {
            const subject = value.charAt(0);
            if (subject === 'M') {
                return 'text-green';
            } else if (subject === 'S') {
                return 'text-navy';
            } else if (subject === 'R') {
                return 'text-purple';
            }
        } else if (type === 'เปอเซ็นต์ที่ตอบถูก') {
            return 'text-red';
        }
    }
}
