import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { TokenService } from '@app/api';

@Component({
  selector: 'fe-logout',
  templateUrl: './fe-logout.component.html',
  styleUrls: ['./fe-logout.component.scss']
})
export class FeLogoutComponent implements OnInit {

  @Output() success = new EventEmitter();

  constructor(
    private authService: AuthService,
    private tokenService: TokenService) {
  }

  ngOnInit() {
  }

  logout() {
    this.tokenService.apiTokenLogoutPost$()
      .subscribe();
    this.authService.logout();
    this.success.emit();
  }

}
