import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef, ContentChild } from '@angular/core';

@Component({
  selector: 'lib-grid-header-wrapper',
  templateUrl: './grid-header-wrapper.component.html',
  styleUrls: ['./grid-header-wrapper.component.scss']
})
export class GridHeaderWrapperComponent implements OnInit {

  @ViewChild('template') template: TemplateRef<any>;
  @ContentChild('contentTemplate') contentTemplate: TemplateRef<any>;

  constructor() { }

  ngOnInit() {
  }

}
