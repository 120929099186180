import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import * as _ from 'lodash';

export interface Pagination {
  label: string;
  value: any;
}

@Component({
  selector: 'lib-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() page = 1;

  @Input()
  set pageCount(pageCount: number) {
    this._pageCount = pageCount;
    this.pages = this.createPageArray(this.page, pageCount, this.maxSize);
  }
  get pageCount() {
    return this._pageCount;
  }
  private _pageCount: number;

  @Output() pageChange = new EventEmitter<number>();

  pages: Pagination[] = [];
  maxSize = 7;

  constructor() { }

  ngOnInit() {
  }

  setPage(pageItem: Pagination) {
    if (pageItem.label !== '...') {
      this.pageChange.emit(pageItem.value);
      window.setTimeout(() => {
        this.pages = this.createPageArray(pageItem.value, this._pageCount, this.maxSize);
      }, 400); // set equal to busy delay for changing when busy loading display
    }
  }


  /**
   * Returns an array of Page objects to use in the pagination controls.
   */
  private createPageArray(currentPage: number, totalPages: number, paginationRange: number): Pagination[] {
    // paginationRange could be a string if passed from attribute, so cast to number.
    paginationRange = +paginationRange;
    const pages = [] as Pagination[];
    const halfWay = Math.ceil(paginationRange / 2);

    const isStart = currentPage <= halfWay;
    const isEnd = totalPages - halfWay < currentPage;
    const isMiddle = !isStart && !isEnd;

    const ellipsesNeeded = paginationRange < totalPages;
    let i = 1;

    while (i <= totalPages && i <= paginationRange) {
      let label;
      const pageNumber = this.calculatePageNumber(i, currentPage, paginationRange, totalPages);
      const openingEllipsesNeeded = (i === 2 && (isMiddle || isEnd));
      const closingEllipsesNeeded = (i === paginationRange - 1 && (isMiddle || isStart));
      if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
        label = '...';
      } else {
        label = pageNumber;
      }
      pages.push({
        label: label,
        value: pageNumber
      });
      i++;
    }
    return pages;
  }

  /**
   * Given the position in the sequence of pagination links [i],
   * figure out what page number corresponds to that position.
   */
  private calculatePageNumber(i: number, currentPage: number, paginationRange: number, totalPages: number) {
    const halfWay = Math.ceil(paginationRange / 2);
    if (i === paginationRange) {
      return totalPages;
    } else if (i === 1) {
      return i;
    } else if (paginationRange < totalPages) {
      if (totalPages - halfWay < currentPage) {
        return totalPages - paginationRange + i;
      } else if (halfWay < currentPage) {
        return currentPage - halfWay + i;
      } else {
        return i;
      }
    } else {
      return i;
    }
  }
}
