import { Injectable, Inject } from '@angular/core';
import { AuthServiceBase } from 'soft-ngx';
import { MasterSchoolDropdownDTO } from 'src/app/generated-api/model/masterSchoolDropdownDTO';

export enum AuthData {
  email = 'email',
  name = 'name',
  profilePicture = 'profilePicture'
}

export enum Profile {
  canAccessBooking = 'canAccessBooking',
  school = 'school'
}

@Injectable()
export class AuthService extends AuthServiceBase {

  tempPassword: string;

  getAdditionalAuthData() {
    return Object.keys(AuthData);
  }

  fakeLogin() {
    this.storage.setItem('fakeLoggedIn', true);
  }

  fakeLogout() {
    this.storage.removeItem('fakeLoggedIn');
  }

  get isFakeLoggedIn() {
    return this.storage.getBoolean('fakeLoggedIn');
  }

  get email(): string | null {
    return this.storage.getItem(AuthData.email);
  }

  set name(name: string | null) {
    this.storage.setItem(AuthData.name, name);
  }

  get name(): string | null {
    const name = this.storage.getItem(AuthData.name);
    if (name === 'null') {
      return null;
    }
    return name;
  }

  get profilePictureUrl(): string {
    const profilePicture = this.storage.getItem(AuthData.profilePicture);
    if (!profilePicture || profilePicture === 'null') {
      return 'assets/image/profile.png';
    }
    return profilePicture;
  }

  set canAccessBooking(canAccessBooking: boolean) {
    this.storage.setItem(Profile.canAccessBooking, canAccessBooking);
  }

  get canAccessBooking(): boolean {
    return this.storage.getBoolean(Profile.canAccessBooking);
  }

  set school(school: MasterSchoolDropdownDTO) {
    this.storage.setItem(Profile.school, JSON.stringify(school || {}));
  }

  get school(): MasterSchoolDropdownDTO {
    return JSON.parse(this.storage.getItem(Profile.school) || '{}');
  }
}
