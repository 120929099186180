import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Utils } from '@utils';

@Pipe({
  name: 'htmlSanitize'
})
export class HtmlSanitizePipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer) {
  }

  transform(html: string): any {
    return this.sanitizer.bypassSecurityTrustHtml(Utils.sanitize(html));
  }
}
