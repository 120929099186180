/**
 * encourse Main App Service
 *
 * API version: v1.0
// tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import { ApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class UploadService {

  constructor(
    protected api: ApiClientService,
    private authService: AuthService) {
  }

  /**
   * [Authorize] type: apiKey
   */
  public uploadProfilePicture$(file): Observable<any> {
    return this.api.multipartPost(`/api/Users/UploadProfilePicture`, { file });
  }

  /**
   * Use for Froala Editor
   */
  public getImageUploadApiUrl() {
    return `${this.api.getBaseUrl()}/api/Files/UploadImage`;
  }

  public getAuthHeader() {
    let scheme = this.authService.getAuthenticationScheme() || '';
    if (scheme) {
      scheme += ' ';
    }
    return { Authorization: scheme + this.authService.getAccessToken() };
  }
}
