
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Model } from '@app/api';
import { Model as ModelAdmin } from '@admin/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lib-exam-item',
  templateUrl: './exam-item.component.html',
  styleUrls: ['./exam-item.component.scss']
})
export class ExamItemComponent implements OnInit {

  @Input() question: Model.QuestionDTO | ModelAdmin.QuestionDTO;
  @Input() isSetAnswerAndScore: boolean;
  @Input() isReadOnly: boolean;
  @Input() isShowResult: boolean;
  @Input() isShowCorrectAnswer: boolean;
  @Input() isGrading: boolean;
  @Input() isGradingSaveLoading: boolean;

  @Output() gradingSave = new EventEmitter<any>();

  QuestionTypeEnum = Model.QuestionDTO.QuestionTypeEnum;

  constructor() { }

  ngOnInit() {
  }

}
