import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseDropdown } from '@lib';
import { Model, UsersService } from '@app/api';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-forget-password-question-dropdown',
  template: `
    <div class="select is-fullwidth"
      [class.is-loading]="loading">
      <select
        [attr.name]="name"
        [(ngModel)]="value"
        [compareWith]="compareFn"
        [disabled]="disabled"
        [required]="required"
        [notEmpty]="notEmpty"
        (change)="onSelect()"
      >
        <option
          [disabled]="true"
          [hidden]="true"
          [ngValue]="{}"
        >
          {{ placeholder }}
        </option>
        <option *ngFor="let option of optionList | exclude: _selectedList : idAttr"
          [ngValue]="option">{{ option.description }}
        </option>
      </select>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ForgotPasswordQuestionDropdownComponent),
      multi: true
    }
  ]
})
export class ForgotPasswordQuestionDropdownComponent extends BaseDropdown<Model.QuestionUsrDTO> {

  // forgotQuestions = [
  //   'อาหารจานโปรดของคุณคือ',
  //   'ชื่อสัตว์เลี้ยงของคุณคือ',
  //   'สีโปรดของคุณคือ',
  //   'ตัวเลขที่คุณชอบคือ',
  //   'ชื่อบุคคลที่คุณนึกถึงเป็นคนแรก',
  // ];

  _selectedList: Model.QuestionAnswerUsrDTO[];
  @Input() set selectedList(selectedList: Model.QuestionAnswerUsrDTO[]) {
    window.setTimeout(() => { // wait for writeValue()
      if (this.value) {
        // ignore self to selected list
        this._selectedList = selectedList.filter(m => {
          if (this.value) {
            return m[this.idAttr] !== this.value[this.idAttr];
          }
          return true;
        });
      } else {
        this._selectedList = selectedList;
      }
    });
  }

  idAttr = 'questionId';

  constructor(
    private usersService: UsersService
  ) {
    super();
  }

  compareFn(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.questionId === o2.questionId : o1 === o2;
  }

  protected apiOptionListGet$() {
    return this.usersService.apiUsersQuestionsDropdownListGet$();
  }
}
