/**
 * encourse-pisa Main App Service
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Injectable } from '@angular/core';
import { ApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';

import { LoginModel } from '../../model/loginModel';


@Injectable()
export class TokenService {

  constructor(
    protected api: ApiClientService) {
  }

  /**
   * [Authorize] type: apiKey
   */
  public apiTokenLogoutPost$(): Observable<any> {
    return this.api.post(`/api/Token/Logout`, undefined, undefined);
  }
  /**
   */
  public apiTokenPost$(login?: LoginModel): Observable<any> {
    return this.api.post(`/api/Token`, login, undefined, true);
  }
}
