/**
 * encourse-pisa Main App Service
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Injectable } from '@angular/core';
import { ApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';

import { MasterSchoolDropdownDTO } from '../../model/masterSchoolDropdownDTO';


@Injectable()
export class SchoolsService {

  constructor(
    protected api: ApiClientService) {
  }

  /**
   */
  public apiSchoolsGet$(schoolId?: string, districtId?: string, provincesId?: string, name?: string): Observable<Array<MasterSchoolDropdownDTO>> {
    return this.api.get(`/api/Schools`, { schoolId, districtId, provincesId, name }, true);
  }
}
