import { Component, OnDestroy, OnInit } from '@angular/core';
import { UsersService } from '@app/api';
import { Subscription, throwError } from 'rxjs';
import { catchError, delay, map, retryWhen } from 'rxjs/operators';
import { ApiError } from 'soft-ngx';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  // onlineUsers = 1;

  // private getOnlineSub: Subscription;

  // constructor(
  //   private usersService: UsersService,
  // ) {
  // }

  ngOnInit() {
    // if (this.getOnlineSub) {
    //   this.getOnlineSub.unsubscribe();
    // }
    // this.getOnlineInterval();
  }

  ngOnDestroy(): void {
    // if (this.getOnlineSub) {
    //   this.getOnlineSub.unsubscribe();
    // }
  }

  // private getOnlineInterval() {
  //   this.getOnlineSub = this.usersService.apiUsersOnlineGet$().pipe(
  //     map((data: number) => {
  //       this.onlineUsers = data || 1;
  //       return this.onlineUsers;
  //     }),
  //     catchError((err: ApiError) => {
  //       err.ignoreGlobalErrorAlert();
  //       return throwError(err);
  //     }),
  //     retryWhen(err$ => err$.pipe(delay(60 * 1000))),
  //     delay(60 * 1000),
  //   ).subscribe(() => {
  //     this.getOnlineInterval();
  //   });
  // }

}
