/**
 * encourse-pisa Main App Service
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Injectable } from '@angular/core';
import { ApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';



@Injectable()
export class LoaderVerifyService {

  constructor(
    protected api: ApiClientService) {
  }

  /**
   */
  public loaderio9fd40ae413a7a2c5855347341be8c89dGet$(): Observable<string> {
    return this.api.get(`/loaderio-9fd40ae413a7a2c5855347341be8c89d`, undefined, true);
  }
}
