/**
 * encourse-pisa Main App Service
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AnalysisMasterDTO } from './analysisMasterDTO';
import { AnalysisPerQuestionDTO } from './analysisPerQuestionDTO';
import { AnalysisResultTableDTO } from './analysisResultTableDTO';
import { BarChartDTO } from './barChartDTO';
import { ColumnChartDTO } from './columnChartDTO';
import { EvaluationDTO } from './evaluationDTO';

export interface StudentReportDTO {
    personalId: string;
    name: string;
    sex: string;
    grade: StudentReportDTO.GradeEnum;
    school: string;
    eaName: string;
    province: string;
    attemptCount: number;
    startTime: Date;
    evaluations: Array<EvaluationDTO>;
    analysisPerQuestion: AnalysisPerQuestionDTO;
    analysisMasters: Array<AnalysisMasterDTO>;
    analysisResultTable: AnalysisResultTableDTO;
    barChart: Array<BarChartDTO>;
    columnChart: ColumnChartDTO;
}
export namespace StudentReportDTO {
    export type GradeEnum = 'Grade4' | 'Grade5' | 'Grade6' | 'Grade7' | 'Grade8' | 'Grade9' | 'Grade10' | 'Grade11' | 'Grade12';
    export const GradeEnum = {
        Grade4: 'Grade4' as GradeEnum,
        Grade5: 'Grade5' as GradeEnum,
        Grade6: 'Grade6' as GradeEnum,
        Grade7: 'Grade7' as GradeEnum,
        Grade8: 'Grade8' as GradeEnum,
        Grade9: 'Grade9' as GradeEnum,
        Grade10: 'Grade10' as GradeEnum,
        Grade11: 'Grade11' as GradeEnum,
        Grade12: 'Grade12' as GradeEnum
    };
}
