import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseDropdown } from '@lib';
import { Model, SchoolsService } from '@app/api';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-school-dropdown',
  template: `
    <div class="select is-fullwidth"
      [class.is-loading]="loading">
      <select
        [attr.name]="name"
        [(ngModel)]="value"
        [compareWith]="compareFn"
        [disabled]="disabled"
        [required]="required"
        (change)="onSelect()"
      >
        <option
          [disabled]="true"
          [hidden]="true"
          [ngValue]="undefined"
        >
          {{ placeholder }}
        </option>
        <option *ngFor="let option of optionList"
          [ngValue]="option">{{ option.name }}
        </option>
      </select>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SchoolDropdownComponent),
      multi: true
    }
  ]
})
export class SchoolDropdownComponent extends BaseDropdown<Model.MasterSchoolDropdownDTO> {

  _district: Model.MasterDistrictDropdownDTO;
  @Input() set district(district: Model.MasterDistrictDropdownDTO) {
    this._district = district;
    this.reset();
    this.getOptionList();
  }

  constructor(
    private subjectService: SchoolsService
  ) {
    super();
  }

  protected apiOptionListGet$() {
    if (this._district) {
      return this.subjectService.apiSchoolsGet$(undefined, this._district.id, undefined);
    }
    return of([] as Model.MasterSchoolDropdownDTO[]);
  }
}
