import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseDropdown } from '@lib';
import { Model } from '@app/api';
import { GradeEnum, grades, gradesText } from '@app/core/grade';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-grade-dropdown',
  template: `
    <div class="select is-fullwidth"
      [class.is-loading]="loading">
      <select
        [attr.name]="name"
        [(ngModel)]="value"
        [compareWith]="compareFn"
        [disabled]="disabled"
        [required]="required"
        (change)="onSelect()"
      >
        <option
          [disabled]="true"
          [hidden]="true"
          [ngValue]="undefined"
        >
          {{ placeholder }}
        </option>
        <option *ngFor="let option of optionList"
          [ngValue]="option">{{ gradesText[option] }}
        </option>
      </select>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GradelDropdownComponent),
      multi: true
    }
  ]
})
export class GradelDropdownComponent extends BaseDropdown<GradeEnum> {

  gradesText = gradesText;

  compareFn(o1: any, o2: any): boolean {
    return o1 === o2;
  }

  constructor(
  ) {
    super();
  }

  protected apiOptionListGet$() {
    return of(grades);
  }
}
