import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { GridHeaderWrapperComponent } from './grid-header-wrapper/grid-header-wrapper.component';
import * as Sortable from 'sortablejs/Sortable';

export interface GridTableColumn extends TableColumn {
  placeholder?: string;
  sortBy?: string;
  filterBy?: string;
  filterOptions?: any[];
  filterDisplayProp?: string;
}

export type FilterType = 'search' | 'equal' | ((row, prop) => boolean);

export interface FilterTypeObject {
  [prop: string]: FilterType;
}

export interface DataFilter {
  prop: string;
  data: any;
  type: FilterType;
}

export interface Page {
  page: number;
  perPage: number;
  totalCount: number;
}

export interface Sort {
  // column prop; send to ngx-datatable's sorts()
  prop: string;
  // sortBy field; send to server
  sortBy: string;
  // sortBy direction; send to server
  asc: boolean;
}

export interface RowReOrder<T> {
  row: T;
  oldIndex: number;
  newIndex: number;
}


@Component({
  selector: 'lib-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent<T> implements OnInit, OnDestroy {
  @Input() columns: GridTableColumn[];

  filteredRows: T[];
  originalRows: T[];
  @Input()
  set rows(rows: T[]) {
    this.filteredRows = rows;
    this.originalRows = [...rows];
    if (this.rowReOrdering) {
      this.setRowReOrderable();
    }
  }

  @Input() externalPaging = false;
  @Input() page: Page;
  perPageOptions = [10, 25, 50, 100];

  @Input() externalSorting = false;
  @Input() sort: Sort;

  @Input() rowReOrdering = false;
  @Input() rowId: any;
  @Input() selectionType: string | undefined = undefined;
  @Input() selected: T[] = [];
  @Input() classStyle = '';

  @Output() pageChange = new EventEmitter<Page>();
  @Output() sortChange = new EventEmitter<Sort>();
  @Output() filter = new EventEmitter<DataFilter>();
  @Output() edit = new EventEmitter<T>();
  @Output() delete = new EventEmitter<T>();
  @Output() visible = new EventEmitter<T>();
  @Output() selectedChange = new EventEmitter<T[]>();
  @Output() rowReOrderChange = new EventEmitter<RowReOrder<T>>();

  // header template
  @ViewChild('searchableHeaderWrapper')
  searchableHeaderWrapper: GridHeaderWrapperComponent;
  @ViewChild('dropdownHeaderWrapper')
  dropdownHeaderWrapper: GridHeaderWrapperComponent;

  // cell template
  @ViewChild('nameTpl') nameTpl: TemplateRef<any>;
  @ViewChild('actionTpl') actionTpl: TemplateRef<any>;
  @ViewChild('actionDeleteTpl') actionDeleteTpl: TemplateRef<any>;
  @ViewChild('actionWithVisibleTpl') actionWithVisibleTpl: TemplateRef<any>;
  @ViewChild('dateTpl') dateTpl: TemplateRef<any>;
  @ViewChild('dateTimeTpl') dateTimeTpl: TemplateRef<any>;
  @ViewChild('timeTpl') timeTpl: TemplateRef<any>;
  @ViewChild('reOrderTpl') reOrderTpl: TemplateRef<any>;

  mFilter: { [key: string]: any } = {};

  private searchTerm$ = new Subject<DataFilter>();
  private pageChange$ = new Subject<Page>();
  private sortChange$ = new Subject<Sort>();
  private destroy$ = new Subject<boolean>();
  private sortablejs: any;
  private that: any = this;

  constructor() { }

  ngOnInit() {
    this.searchTerm$
      .pipe(
        debounceTime(400),
        takeUntil(this.destroy$)
      )
      .subscribe((dataFilter: DataFilter) => {
        this.filter.emit(dataFilter);
      });

    this.pageChange$
      .pipe(
        debounceTime(400),
        takeUntil(this.destroy$)
      )
      .subscribe((page: Page) => this.pageChange.emit(page));

    this.sortChange$
      .pipe(
        debounceTime(400),
        takeUntil(this.destroy$)
      )
      .subscribe((sort: Sort) => this.sortChange.emit(sort));
  }

  count() {
    if (this.page) {
      return this.page.totalCount;
    }
    return 0;
  }

  offset() {
    if (this.page) {
      return this.page.page - 1;
    }
    return 0;
  }

  limit() {
    if (this.page) {
      return this.page.perPage;
    }
    return undefined;
  }

  setPage(event) {
    const page: Page = {
      page: event.offset + 1,
      perPage: event.limit,
      totalCount: event.count
    };
    this.pageChange$.next(page);
  }

  setPerPage(perPage) {
    const page: Page = {
      page: this.page.page,
      perPage,
      totalCount: this.page.totalCount
    };
    this.pageChange$.next(page);
  }

  sorts() {
    if (this.sort) {
      return [
        {
          prop: this.sort.prop,
          dir: this.sort.asc ? 'asc' : 'desc'
        }
      ];
    }
    return undefined;
  }

  onSort(event) {
    if (!event.sorts) {
      return;
    }
    const sort: Sort = {
      prop: event.sorts[0].prop, // keep prop for sorts() to determine the column's arrow direction
      sortBy: event.column.sortBy || event.sorts[0].prop, // use custom sortBy attr with fallback to prop
      asc: event.sorts[0].dir === 'asc'
    };
    this.sort = sort;
    this.sortChange$.next(sort);
  }

  onSearch(column, value) {
    this.searchTerm$.next({
      prop: column.filterBy || column.prop,
      data: value,
      type: 'search'
    });
  }

  onFilter(column) {
    this.filter.emit({
      prop: column.filterBy || column.prop,
      data: this.mFilter[column.prop],
      type: 'equal'
    });
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    this.selectedChange.emit(this.selected);
  }

  rowIdentity = row => {
    if (this.rowId) {
      return row[this.rowId];
    }
    return row;
    // tslint:disable-next-line:semicolon
  };

  selectCheck = (row, column, value) => {
    return true;
    // tslint:disable-next-line:semicolon
  };

  internalFilter(
    filter: { [prop: string]: any },
    filterType: FilterTypeObject
  ) {
    let temp = this.originalRows;
    const props = Object.keys(filterType);
    for (const prop of props) {
      temp = temp.filter(row => {
        if (filterType[prop] === 'search') {
          return (
            row[prop].toLowerCase().indexOf(filter[prop]) !== -1 ||
            !filter[prop]
          );
        }
        if (filterType[prop] === 'equal') {
          return row[prop] === filter[prop] || !filter[prop];
        }
        if (typeof filterType[prop] === 'function') {
          return (filterType[prop] as Function)(row, prop);
        }
      });
    }
    this.filteredRows = temp;
  }

  setRowReOrderable() {
    window.setTimeout(() => {
      const el = document.getElementsByTagName('datatable-scroller')[0];

      if (!el) {
        return;
      }

      if (this.sortablejs) {
        this.sortablejs.destroy();
      }

      this.sortablejs = new Sortable(el, {
        draggable: '.datatable-row-wrapper',
        handle: '.reorder-handle',
        onEnd: (evt) => {
          // reorder by dom
          const itemDom = evt.item;
          if (evt.oldIndex > evt.newIndex) {
            itemDom.parentElement.insertBefore(itemDom, evt.target.children[evt.oldIndex].nextElementSibling);
          } else {
            itemDom.parentElement.insertBefore(itemDom, evt.target.children[evt.oldIndex]);
          }

          // reorder by data structure
          const itemObject = this.filteredRows.splice(evt.oldIndex, 1)[0];
          this.filteredRows.splice(evt.newIndex, 0, itemObject);
          this.filteredRows = [...this.filteredRows];

          // find target id
          // const rowClasses = item.children[0].className;
          // const generatedClassPrefix = getRowClass({ id: '' });
          // const id = rowClasses.substr(rowClasses.indexOf(generatedClassPrefix) + generatedClassPrefix.length);

          this.rowReOrderChange.emit({
            row: itemObject,
            oldIndex: evt.oldIndex,
            newIndex: evt.newIndex
          });
        }
      });
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
