/**
 * encourse-pisa Main App Service
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Injectable } from '@angular/core';
import { ApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';

import { StudentReportDTO } from '../../model/studentReportDTO';


@Injectable()
export class ReportsService {

  constructor(
    protected api: ApiClientService) {
  }

  /**
   * [Authorize] type: apiKey
   */
  public apiReportsStudentsGet$(examTestResultId?: string, reportDate?: Date): Observable<StudentReportDTO> {
    return this.api.get(`/api/Reports/Students`, { examTestResultId, reportDate });
  }
}
