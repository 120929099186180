<nav class="navbar is-transparent _header">
  <div class="container">
    <div class="navbar-brand ">
      <div class="navbar-item">
        <p class="_header-title">PISA STYLE</p>
      </div>
    </div>

    <div
      class="navbar-menu"
      *ngIf="showLoginAndProfile"
    >
      <div class="navbar-end">
        <ng-container *ngIf="!isLoggedIn">
          <div class="navbar-item">
            <fe-login-horizontal
              (success)="afterLogin()"
              (forceChangePassword)="forceChangePassword()"
              (viewRegister)="viewRegister()"
              (viewForgotPassword)="viewForgotPassword()"
            ></fe-login-horizontal>
          </div>
        </ng-container>
        <ng-container *ngIf="isLoggedIn">
          <div
            *ngIf="canAccessBooking"
            class="navbar-item"
          >
            <a
              href="/queue/"
              type="button"
              class="button is-link has-text-weight-normal"
            >จองคิวสอบ</a>
          </div>

          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link has-text-secondary">
              <span class="icon icon-em">
                <i class="ic ic-user"></i>
              </span>
              <span id="username">{{ userName }}</span>
              <div class="_arrow-dropdown">
                <span></span>
              </div>
            </a>

            <div class="navbar-dropdown is-right is-boxed">
              <a
                class="navbar-item"
                [routerLink]="['/profile']"
              >
                ข้อมูลของฉัน
              </a>
              <a
                class="navbar-item"
                [routerLink]="['/examHistory']"
              >
                ประวัติการทำข้อสอบ
              </a>
              <a class="navbar-item">
                <fe-logout (success)="afterLogout()">
                  ออกจากระบบ
                </fe-logout>
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</nav>