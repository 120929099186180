import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getRemarkBySubjectPoint'
})
export class GetRemarkBySubjectPointPipe implements PipeTransform {

  transform(value: number, subject: string): any {
    if(value < 25){
      return `ความสามารถการรู้เรื่อง${subject}ควรปรับปรุง`;
    } else if(value >= 25 && value < 65){
      return `ความสามารถการรู้เรื่อง${subject}อยู่ในระดับปานกลาง`;
    } else if( value >= 65){
      return `ความสามารถการรู้เรื่อง${subject}อยู่ในระดับยอดเยี่ยม`;
    }
  }

}
