import { Pipe, PipeTransform } from '@angular/core';
import { Model } from '@admin/api';

@Pipe({
  name: 'evaluationsResult'
})
export class EvaluationsResultPipe implements PipeTransform {
  transform(value: Model.EvaluationDTO, type: string, subject: string): any {
    switch (subject) {
      case 'math':
        if (type === 'คะแนนรายบุคคล (%)' || type === 'คะแนนเฉลี่ยของประเทศไทย (%)') {
          return `${value.mathResult}`;
        } else if (type === 'คะแนนในระดับชั้น (MIN%/AVG%/MAX%)') {
          return `${value.gradeMinMath}/${value.gradeAvgMath}/${value.gradeMaxMath}`;
        } else {
          return `${value.mathRank} จาก ${value.totalCount} คน`;
        }
      case 'science':
        if (type === 'คะแนนรายบุคคล (%)' || type === 'คะแนนเฉลี่ยของประเทศไทย (%)') {
          return `${value.scienceResult}`;
        } else if (type === 'คะแนนในระดับชั้น (MIN%/AVG%/MAX%)') {
          return `${value.gradeMinScience}/${value.gradeAvgScience}/${value.gradeMaxScience}`;
        } else {
          return `${value.scienceRank} จาก ${value.totalCount} คน`;
        }
      case 'reading':
        if (type === 'คะแนนรายบุคคล (%)' || type === 'คะแนนเฉลี่ยของประเทศไทย (%)') {
          return `${value.readingResult}`;
        } else if (type === 'คะแนนในระดับชั้น (MIN%/AVG%/MAX%)') {
          return `${value.gradeMinReading}/${value.gradeAvgReading}/${value.gradeMaxReading}`;
        } else {
          return `${value.readingRank} จาก ${value.totalCount} คน`;
        }
      case 'overall':
        if (type === 'คะแนนรายบุคคล (%)' || type === 'คะแนนเฉลี่ยของประเทศไทย (%)') {
          return `${value.overallResult}`;
        } else if (type === 'คะแนนในระดับชั้น (MIN%/AVG%/MAX%)') {
          return `${value.gradeTotalMin}/${value.gradeTotalAvg}/${value.gradeTotalMax}`;
        } else {
          return `${value.overallRank} จาก ${value.totalCount} คน`;
        }
    }
  }
}
