import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FeLoginComponent } from '../fe-login/fe-login.component';

@Component({
  selector: 'fe-login-horizontal',
  templateUrl: './fe-login-horizontal.component.html',
  styleUrls: ['./fe-login-horizontal.component.scss']
})
export class FeLoginHorizontalComponent extends FeLoginComponent implements OnInit {

}
