/**
 * encourse-pisa Main App Service
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Injectable } from '@angular/core';
import { ApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';

import { BookingDTO } from '../../model/bookingDTO';
import { BookingDayDTO } from '../../model/bookingDayDTO';
import { OnlineCountDTO } from '../../model/onlineCountDTO';


@Injectable()
export class BookingsService {

  constructor(
    protected api: ApiClientService) {
  }

  /**
   * [Authorize] type: apiKey
   */
  public apiBookingsByIdDelete$(id: string): Observable<any> {
    return this.api.delete(`/api/Bookings/${encodeURIComponent(String(id))}`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiBookingsGet$(StartYear?: number, StartMonth?: number, StartDay?: number, EndYear?: number, EndMonth?: number, EndDay?: number): Observable<Array<BookingDayDTO>> {
    return this.api.get(`/api/Bookings`, { StartYear, StartMonth, StartDay, EndYear, EndMonth, EndDay });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiBookingsMyBookingsCompletedGet$(): Observable<Array<BookingDTO>> {
    return this.api.get(`/api/Bookings/MyBookings/Completed`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiBookingsMyBookingsWaitingGet$(): Observable<Array<BookingDTO>> {
    return this.api.get(`/api/Bookings/MyBookings/Waiting`, undefined);
  }
  /**
   */
  public apiBookingsOnlineGet$(): Observable<OnlineCountDTO> {
    return this.api.get(`/api/Bookings/Online`, undefined, true);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiBookingsPost$(input?: BookingDTO): Observable<BookingDTO> {
    return this.api.post(`/api/Bookings`, input, undefined);
  }
}
