import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';

import { VForgotPasswordQuestionControlsComponent } from './v-forgot-password-question-controls/v-forgot-password-question-controls.component';
import { VNewPasswordControlsComponent } from './v-new-password-controls/v-new-password-controls.component';
import { VProfileControlsComponent } from './v-profile-controls/v-profile-controls.component';
import { VProfileInfoComponent } from './v-profile-info/v-profile-info.component';
import { VForgotPasswordQuestionInfoComponent } from './v-forgot-password-question-info/v-forgot-password-question-info.component';
import { VRecaptchaComponent } from './v-recaptcha/v-recaptcha.component';

@NgModule({
  declarations: [
    VForgotPasswordQuestionControlsComponent,
    VNewPasswordControlsComponent,
    VProfileControlsComponent,
    VProfileInfoComponent,
    VForgotPasswordQuestionInfoComponent,
    VRecaptchaComponent,
  ],
  imports: [
    SharedModule
  ],
  exports: [
    VForgotPasswordQuestionControlsComponent,
    VNewPasswordControlsComponent,
    VProfileControlsComponent,
    VProfileInfoComponent,
    VForgotPasswordQuestionInfoComponent,
    VRecaptchaComponent
  ]
})
export class AuthProfileSharedModule { }
