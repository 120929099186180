/**
 * encourse-pisa Main App Service
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Injectable } from '@angular/core';
import { HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';

import { DragDropQuestionDTO } from '../../model/dragDropQuestionDTO';
import { ExamContentDTO } from '../../model/examContentDTO';
import { ExamDTO } from '../../model/examDTO';
import { ExamResultDTO } from '../../model/examResultDTO';
import { ExamTestQuestionMapDTO } from '../../model/examTestQuestionMapDTO';
import { HtmlContentDTO } from '../../model/htmlContentDTO';
import { MultipleAnswerQuestionDTO } from '../../model/multipleAnswerQuestionDTO';
import { MultipleChoiceQuestionDTO } from '../../model/multipleChoiceQuestionDTO';
import { MultipleChoiceReasonQuestionDTO } from '../../model/multipleChoiceReasonQuestionDTO';
import { QuestionDTO } from '../../model/questionDTO';
import { ShortAnswerQuestionDTO } from '../../model/shortAnswerQuestionDTO';
import { SubjectiveQuestionDTO } from '../../model/subjectiveQuestionDTO';
import { TabledMultipleAnswerQuestionDTO } from '../../model/tabledMultipleAnswerQuestionDTO';
import { TabledMultipleQuestionDTO } from '../../model/tabledMultipleQuestionDTO';
import { UpdateQuestionCode } from '../../model/updateQuestionCode';
import { UpdateTimeUsedDTO } from '../../model/updateTimeUsedDTO';
import { UpdateTimeUsedParam } from '../../model/updateTimeUsedParam';
import { ExamApiClientService } from '../exam-api-client.service';


@Injectable()
export class ExamsService {

  constructor(
    protected api: ExamApiClientService) {
  }

  setExamEndpointUrl(url: string) {
    this.api.config.apiBaseUrl = url;
  }

  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdGet$(id: string, learnerUserId?: string): Observable<ExamContentDTO> {
    return this.api.get(`/api/Exams/${encodeURIComponent(String(id))}`, { learnerUserId });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdPartsByPartIdHtmlContentGet$(id: string, partId: string): Observable<HtmlContentDTO> {
    return this.api.get(`/api/Exams/${encodeURIComponent(String(id))}/Parts/${encodeURIComponent(String(partId))}/HtmlContent`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionMapGet$(id: string): Observable<ExamTestQuestionMapDTO> {
    return this.api.get(`/api/Exams/${encodeURIComponent(String(id))}/QuestionMap`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionsByQuestionIdAnswerDragDropPost$(id: string, questionId: string, input?: DragDropQuestionDTO): Observable<any> {
    return this.api.post(`/api/Exams/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}/AnswerDragDrop`, input, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionsByQuestionIdAnswerMultipleAnswerPost$(id: string, questionId: string, input?: MultipleAnswerQuestionDTO): Observable<any> {
    return this.api.post(`/api/Exams/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}/AnswerMultipleAnswer`, input, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionsByQuestionIdAnswerMultipleChoicePost$(id: string, questionId: string, input?: MultipleChoiceQuestionDTO): Observable<any> {
    return this.api.post(`/api/Exams/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}/AnswerMultipleChoice`, input, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionsByQuestionIdAnswerMultipleChoiceReasonPost$(id: string, questionId: string, input?: MultipleChoiceReasonQuestionDTO): Observable<any> {
    return this.api.post(`/api/Exams/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}/AnswerMultipleChoiceReason`, input, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionsByQuestionIdAnswerShortAnswerPost$(id: string, questionId: string, input?: ShortAnswerQuestionDTO): Observable<any> {
    return this.api.post(`/api/Exams/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}/AnswerShortAnswer`, input, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionsByQuestionIdAnswerSubjectivePost$(id: string, questionId: string, input?: SubjectiveQuestionDTO): Observable<any> {
    return this.api.post(`/api/Exams/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}/AnswerSubjective`, input, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionsByQuestionIdAnswerTabledMultipleAnswerPost$(id: string, questionId: string, input?: TabledMultipleAnswerQuestionDTO): Observable<any> {
    return this.api.post(`/api/Exams/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}/AnswerTabledMultipleAnswer`, input, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionsByQuestionIdAnswerTabledMultipleQuestionPost$(id: string, questionId: string, input?: TabledMultipleQuestionDTO): Observable<any> {
    return this.api.post(`/api/Exams/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}/AnswerTabledMultipleQuestion`, input, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionsByQuestionIdGet$(id: string, questionId: string, examTestResultId: string): Observable<QuestionDTO> {
    return this.api.get(`/api/Exams/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}`, { examTestResultId });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionsByQuestionIdHtmlContentGet$(id: string, questionId: string): Observable<HtmlContentDTO> {
    return this.api.get(`/api/Exams/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}/HtmlContent`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionsByQuestionIdNextGet$(id: string, questionId: string, examTestResultId: string): Observable<QuestionDTO> {
    return this.api.get(`/api/Exams/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}/Next`, { examTestResultId });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionsByQuestionIdPreviousGet$(id: string, questionId: string, examTestResultId: string): Observable<QuestionDTO> {
    return this.api.get(`/api/Exams/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}/Previous`, { examTestResultId });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdStartPost$(id: string): Observable<any> {
    return this.api.post(`/api/Exams/${encodeURIComponent(String(id))}/Start`, undefined, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdSubmitPost$(id: string): Observable<any> {
    return this.api.post(`/api/Exams/${encodeURIComponent(String(id))}/Submit`, undefined, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdUpdateTimeUsedPost$(id: string, input?: UpdateTimeUsedParam): Observable<UpdateTimeUsedDTO> {
    return this.api.post(`/api/Exams/${encodeURIComponent(String(id))}/UpdateTimeUsed`, input, undefined);
  }
  /**
   */
  public apiExamsQuestionCodeGet$(): Observable<Array<UpdateQuestionCode>> {
    return this.api.get(`/api/Exams/QuestionCode`, undefined, true);
  }
  /**
   */
  public apiExamsQuestionCodePut$(): Observable<Array<UpdateQuestionCode>> {
    return this.api.put(`/api/Exams/QuestionCode`, undefined, undefined, true);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsQuestionsByQuestionIdDropdownByDropdownTagGet$(questionId: string, dropdownTag: number): Observable<Array<string>> {
    return this.api.get(`/api/Exams/Questions/${encodeURIComponent(String(questionId))}/Dropdown/${encodeURIComponent(String(dropdownTag))}`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsStartRandomPost$(): Observable<ExamContentDTO> {
    return this.api.post(`/api/Exams/StartRandom`, undefined, undefined);
  }
}
