import { Model } from '@app/api';

export type GradeEnum = Model.UserManageListDTO.ClassRoomEnum;
export const GradeEnum = Model.UserManageListDTO.ClassRoomEnum;

export const grades = Object.keys(GradeEnum).map(key => GradeEnum[key]);

export const gradesText = {
  [GradeEnum.Grade4]: 'ป.4',
  [GradeEnum.Grade5]: 'ป.5',
  [GradeEnum.Grade6]: 'ป.6',
  [GradeEnum.Grade7]: 'ม.1',
  [GradeEnum.Grade8]: 'ม.2',
  [GradeEnum.Grade9]: 'ม.3',
  [GradeEnum.Grade10]: 'ม.4',
  [GradeEnum.Grade11]: 'ม.5',
  [GradeEnum.Grade12]: 'ม.6'
};
