<form
  novalidate
  #form="ngForm"
  (ngSubmit)="login(form)"
>
  <div class="field is-horizontal">

    <div>
      <p class="control is-expanded">
        <input
          class="input"
          type="text"
          name="idCode"
          placeholder="เลขบัตรประชาชน"
          autocomplete="off"
          [(ngModel)]="idCode"
        >
      </p>
      <a
        class="help is-link is-underline"
        (click)="onViewRegister()"
      >สร้างบัญชีผู้ใช้งานใหม่</a>
    </div>

    <div class="is-margin-left">
      <p class="control is-expanded">
        <input
          class="input"
          type="password"
          name="password"
          placeholder="Password"
          autocomplete="new-password"
          [(ngModel)]="password"
        >
      </p>
      <a
        class="help is-link is-underline"
        (click)="onViewForgotPassword()"
      >ลืมรหัสผ่าน</a>
    </div>

    <div class="is-margin-left">
      <div class="control">
        <button
          class="button is-link has-text-weight-normal"
          type="submit"
          [loadingState]="loginSub"
          (click)="login(form)"
        >
          เข้าสู่ระบบ
        </button>
      </div>
    </div>

  </div>

  <div
    class="_error-text"
    *ngIf="errorText"
  >
    {{ errorText }}
  </div>

</form>