import { NgModule } from '@angular/core';

import { AuthGuard } from './auth-guard';
import { DevGuard } from './dev-guard';

@NgModule({
  providers: [
    AuthGuard,
    DevGuard
  ]
})
export class GuardsModule { }
