import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LibModule } from '@lib';

import {
  BusyModule,
  ModalModule,
  ModelHelperModule,
  PipeExtensionModule
} from 'soft-ngx';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MomentModule } from 'ngx-moment';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CustomFormsModule } from 'ngx-custom-validators';

import { ContentTitleComponent } from './components/content-title/content-title.component';

import { ProvinceDropdownComponent } from './components/dropdown/province-dropdown.component';
import { DistrictDropdownComponent } from './components/dropdown/district-dropdown.component';
import { ForgotPasswordQuestionDropdownComponent } from './components/dropdown/forgot-password-question-dropdown.component';
import { GradelDropdownComponent } from './components/dropdown/grade-dropdown.component';
import { SchoolDropdownComponent } from './components/dropdown/school-dropdown.component';

import { NotEmptyDirective } from './directives/not-empty.directive';

import { EvaluationsResultPipe } from './pipes/evaluations-result.pipe';
import { GetClassByValuePipe } from './pipes/get-class-by-value.pipe';
import { AnalysisPerQuestionDetailsPipe } from './pipes/analysis-per-question-details.pipe';
import { GetRemarkBySubjectPointPipe } from './pipes/get-remark-by-subject-point.pipe';
import { ThaidatePipe } from './pipes/thaidate.pipe';

const internalPipes = [
  EvaluationsResultPipe,
  GetClassByValuePipe,
  AnalysisPerQuestionDetailsPipe,
  GetRemarkBySubjectPointPipe,
  ThaidatePipe
];

const internalDirectives = [
  NotEmptyDirective
];

const internalComponents = [
  ContentTitleComponent,

  // dropdowns
  ForgotPasswordQuestionDropdownComponent,
  ProvinceDropdownComponent,
  DistrictDropdownComponent,
  GradelDropdownComponent,
  SchoolDropdownComponent,
];

@NgModule({
  declarations: [
    [...internalPipes],
    [...internalDirectives],
    [...internalComponents],
  ],
  imports: [
    CommonModule,
    FormsModule,
    LibModule,
    CustomFormsModule
  ],
  exports: [
    // angular
    CommonModule,
    FormsModule,
    RouterModule,
    LibModule,

    // soft-ngx
    BusyModule,
    ModalModule,
    ModelHelperModule,
    PipeExtensionModule,

    // others
    CustomFormsModule,
    BsDatepickerModule,
    MomentModule,
    NgxDatatableModule,

    // internal
    [...internalPipes],
    [...internalDirectives],
    [...internalComponents]
  ]
})
export class SharedModule { }
