import { Injectable } from '@angular/core';
import { drawDOM, exportPDF, DrawOptions, exportImage, exportSVG } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';

@Injectable()
export class ExportPdfService {

  export(element: HTMLElement, fileName: string) {
    return drawDOM(element, { paperSize: 'A4', forcePageBreak: '.pagebreak', scale: 0.5 })
      .then(data => {
        return exportPDF(data, { multiPage: true });
      })
      .then(dataUri => {
        saveAs(dataUri, `${fileName}.pdf`);
      });
  }

  svg2img(element) {
    const xml = new XMLSerializer().serializeToString(element);
    const svg64 = btoa(unescape(encodeURIComponent(xml)));
    const b64start = 'data:image/svg+xml;base64,';
    const image64 = b64start + svg64;
    return image64;
  }
}
