import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() showLoginAndProfile: boolean;

  isLoggedIn: boolean;
  canAccessBooking: boolean;
  userName: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.get('loggedIn')) {
        this.setLoggedIn();
      }
    });
    this.setLoggedIn();
  }

  afterLogin() {
    this.setLoggedIn();
    this.router.navigate(['/']);
  }

  afterLogout() {
    this.isLoggedIn = false;
    this.router.navigate(['/']);
  }

  forceChangePassword() {
    this.router.navigate(['/auth/reset-password']);
  }

  viewRegister() {
    this.router.navigate(['/auth/register']);
  }

  viewForgotPassword() {
    this.router.navigate(['/auth/forgot-password']);
  }

  private setLoggedIn() {
    this.isLoggedIn = this.authService.isLoggedIn!;
    this.userName = this.authService.name!;
    this.canAccessBooking = this.authService.canAccessBooking;
  }
}
