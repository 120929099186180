import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';
import { Page, Sort } from '@lib';

interface StateStore {
  page: Page;
  sort: Sort;
  filter: any;
  externalFilter: any;
  extra: any;
}

@Injectable()
export class StateService {
  store = {} as { [route: string]: StateStore };
  isPopstate = false;

  constructor(
    private router: Router,
  ) {
    router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          this.isPopstate = true;
        } else if (event.navigationTrigger === 'imperative') {
          this.isPopstate = false;
        }
      });
  }

  save(page: Page, sort: Sort, filter?, externalFilter?, extra?) {
    this.store[this.router.url] = {
      page,
      sort,
      filter,
      externalFilter,
      extra,
    };
  }

  load(): StateStore | false {
    return this.isPopstate && this.store[this.router.url];
  }


}
