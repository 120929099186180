import { NgModule, ModuleWithProviders } from '@angular/core';

import { ExamsService } from './api/exams.service';
import { ExamApiClientService } from './exam-api-client.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    ExamApiClientService,
    ExamsService,
  ]
})
export class ExamApiModule { }
