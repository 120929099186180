import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';

import { AuthProfileSharedModule } from '../+shared/auth-profile-shared/auth-profile-shared.module';

import { FeForgotPasswordComponent } from './fe-forgot-password/fe-forgot-password.component';
import { FeLoginComponent } from './fe-login/fe-login.component';
import { FeLoginHorizontalComponent } from './fe-login-horizontal/fe-login-horizontal.component';
import { FeRegisterComponent } from './fe-register/fe-register.component';
import { FeResetPasswordComponent } from './fe-reset-password/fe-reset-password.component';
import { FeLogoutComponent } from './fe-logout/fe-logout.component';

@NgModule({
  declarations: [
    FeForgotPasswordComponent,
    FeLoginComponent,
    FeLoginHorizontalComponent,
    FeLogoutComponent,
    FeRegisterComponent,
    FeResetPasswordComponent,
  ],
  imports: [
    SharedModule,
    AuthProfileSharedModule
  ],
  exports: [
    FeForgotPasswordComponent,
    FeLoginComponent,
    FeLoginHorizontalComponent,
    FeLogoutComponent,
    FeRegisterComponent,
    FeResetPasswordComponent,
  ]
})
export class AuthModule { }
