import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { LibModule } from '@lib';

import {
  // Modules
  ApiClientModule,
  AuthModule,
  BusyModule,
  ModalModule,
  ModelHelperModule,
  PipeExtensionModule,
  PopupModule,
  StorageModule,

  // Config Interfaces
  ApiClientConfig,
  AuthServiceConfig,
  AuthInterceptorConfig,
  CustomAuthRequestKey,
  CustomAuthResponseKey,
  StorageConfig,

  // Interceptor
  DateRequestInterceptor,
  NoCacheInterceptor
} from 'soft-ngx';

import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DragulaModule } from 'ng2-dragula';

import { environment } from '@app/env/environment';

import { GuardsModule } from './guards/guards.module';
import { AuthService } from './services/auth.service';
import { UploadService } from './services/upload.service';
import { ExportPdfService } from './services/export-pdf.service';
import { ExamApiModule, RegisterApiModule } from '@app/api';

const apiClientConfig: ApiClientConfig = {
  apiBaseUrl: environment.appApiBaseUrl,
  pageHeaderResponseKeys: {
    pageCount: 'X-Paging-PageCount',
    totalCount: 'X-Paging-TotalRecordCount'
  }
};

const authServiceConfig: AuthServiceConfig = {
  tokenUrl: environment.tokenUrl,
  isFormData: false,
  isJWT: true,
  isOAuth: false,
};

const authInterceptorConfig: AuthInterceptorConfig = {
  autoRefreshToken: false,
  loginScreenUrl: environment.loginScreenUrl
};

const customAuthRequestKey: CustomAuthRequestKey = {
  username: 'idCode',
  password: 'password'
};

const customAuthResponseKey: CustomAuthResponseKey = {
  access_token: 'access_token'
};

const bsDatepickerConfig = Object.assign(new BsDatepickerConfig(), {
  containerClass: 'theme-default',
  dateInputFormat: 'DD/MM/YYYY',
  showWeekNumbers: false
});

const storageConfig: StorageConfig = {
  storagePrefix: environment.storagePrefix
};


@NgModule({
  imports: [
    // angular
    CommonModule,

    // lib
    LibModule.forRoot(),

    // soft-ngx
    ApiClientModule.forRoot(apiClientConfig),
    AuthModule.forRoot(AuthService, {
      authServiceConfig,
      authInterceptorConfig,
      customAuthRequestKey,
      customAuthResponseKey
    }),
    BusyModule.forRoot(),
    ModalModule.forRoot(),
    ModelHelperModule.forRoot(),
    PopupModule.forRoot(),
    StorageModule.forRoot(storageConfig),

    // others
    BsDatepickerModule.forRoot(),
    DragulaModule.forRoot(),

    // internal
    ExamApiModule,
    RegisterApiModule,
    GuardsModule
  ],
  providers: [
    { provide: BsDatepickerConfig, useValue: bsDatepickerConfig },
    { provide: HTTP_INTERCEPTORS, useClass: DateRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheInterceptor, multi: true },
    AuthService,
    UploadService,
    ExportPdfService,
  ]
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule) {

    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import Core modules in the AppModule only.`
    );
  }
}
