import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[notEmpty]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: NotEmptyDirective, multi: true }
  ]
})
export class NotEmptyDirective implements Validator {
  @Input() notEmpty;
  constructor() { }

  validate(control: AbstractControl): { [key: string]: boolean } | null {
    if (!this.notEmpty) {
      return null;
    }
    const value = control.value;
    if (value === undefined
      || value === ''
      || value === null
      || (value !== null && typeof value === 'object' && Object.keys(value).length === 0)
    ) {
      return { notEmpty: true };
    }
    return null;
  }
}
