import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseDropdown } from '@lib';
import { Model, ProvincesService } from '@app/api';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-province-dropdown',
  template: `
    <div class="select is-fullwidth"
      [class.is-loading]="loading">
      <select
        [attr.name]="name"
        [(ngModel)]="value"
        [compareWith]="compareFn"
        [disabled]="disabled"
        [required]="required"
        (change)="onSelect()"
      >
        <option
          [disabled]="true"
          [hidden]="true"
          [ngValue]="undefined"
        >
          {{ placeholder }}
        </option>
        <option *ngFor="let option of optionList"
          [ngValue]="option">{{ option.name }}
        </option>
      </select>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProvinceDropdownComponent),
      multi: true
    }
  ]
})
export class ProvinceDropdownComponent extends BaseDropdown<Model.MasterProvinceDTO> {

  constructor(
    private provincesService: ProvincesService
  ) {
    super();
  }

  protected apiOptionListGet$() {
    return this.provincesService.apiProvincesGet$();
  }
}
