import { NgModule } from '@angular/core';
import { Routes, Route, RouterModule } from '@angular/router';
import { environment } from '@app/env/environment';

import { EmptyLayoutComponent } from './layouts/layout/empty-layout/empty-layout.component';
import { AuthLayoutComponent } from './layouts/layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layouts/layout/main-layout/main-layout.component';
import { AuthGuard } from './core/guards/auth-guard';
import { DevGuard } from './core/guards/dev-guard';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './pages/home-page/home-page.module#HomePageModule'
      }
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: './pages/auth-page/auth-page.module#AuthPageModule'
      }
    ]
  },
  {
    path: '',
    canActivateChild: [AuthGuard],
    component: MainLayoutComponent,
    children: [
      {
        path: 'profile',
        loadChildren: './pages/profile-page/profile-page.module#ProfilePageModule'
      },
      {
        path: 'examHistory',
        loadChildren: './pages/exam-history-page/exam-history-page.module#ExamHistoryPageModule'
      }
    ]
  },
  {
    path: '',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'exam',
        loadChildren: './pages/exam-page/exam-page.module#ExamPageModule'
      },
      {
        path: 'report',
        loadChildren: './pages/report-page/report-page.module#ReportPageModule'
      },
    ]
  },
  // For Development
  {
    path: 'playground',
    canActivateChild: [DevGuard],
    loadChildren: './playground/playground.module#PlaygroundModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
