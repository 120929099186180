/**
 * encourse-pisa Main App Service
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FileResult } from './fileResult';
import { MasterDistrictDropdownDTO } from './masterDistrictDropdownDTO';
import { MasterProvinceDTO } from './masterProvinceDTO';
import { MasterSchoolDropdownDTO } from './masterSchoolDropdownDTO';
import { QuestionAnswerUsrDTO } from './questionAnswerUsrDTO';

export interface UserManageListDTO {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    birthYear: number;
    occupasion: string;
    gender: UserManageListDTO.GenderEnum;
    classRoom: UserManageListDTO.ClassRoomEnum;
    roles: Array<UserManageListDTO.RolesEnum>;
    isActive: boolean;
    password: string;
    province: MasterProvinceDTO;
    district: MasterDistrictDropdownDTO;
    school: MasterSchoolDropdownDTO;
    questionAnswer: Array<QuestionAnswerUsrDTO>;
    idCode: string;
    profilePicture: FileResult;
    gpa: number;
    canAccessBooking: boolean;
}
export namespace UserManageListDTO {
    export type GenderEnum = 'Male' | 'Female' | 'Other' | 'None';
    export const GenderEnum = {
        Male: 'Male' as GenderEnum,
        Female: 'Female' as GenderEnum,
        Other: 'Other' as GenderEnum,
        None: 'None' as GenderEnum
    };
    export type ClassRoomEnum = 'Grade4' | 'Grade5' | 'Grade6' | 'Grade7' | 'Grade8' | 'Grade9' | 'Grade10' | 'Grade11' | 'Grade12';
    export const ClassRoomEnum = {
        Grade4: 'Grade4' as ClassRoomEnum,
        Grade5: 'Grade5' as ClassRoomEnum,
        Grade6: 'Grade6' as ClassRoomEnum,
        Grade7: 'Grade7' as ClassRoomEnum,
        Grade8: 'Grade8' as ClassRoomEnum,
        Grade9: 'Grade9' as ClassRoomEnum,
        Grade10: 'Grade10' as ClassRoomEnum,
        Grade11: 'Grade11' as ClassRoomEnum,
        Grade12: 'Grade12' as ClassRoomEnum
    };
    export type RolesEnum = 'Admin' | 'Central' | 'NFE' | 'Teacher' | 'Student' | 'Approver' | 'Referee' | 'General' | 'NFESpecial';
    export const RolesEnum = {
        Admin: 'Admin' as RolesEnum,
        Central: 'Central' as RolesEnum,
        NFE: 'NFE' as RolesEnum,
        Teacher: 'Teacher' as RolesEnum,
        Student: 'Student' as RolesEnum,
        Approver: 'Approver' as RolesEnum,
        Referee: 'Referee' as RolesEnum,
        General: 'General' as RolesEnum,
        NFESpecial: 'NFESpecial' as RolesEnum
    };
}
