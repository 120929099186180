<section class="section is-fit">
  <!-- <div class="_online">
    ออนไลน์: {{ onlineUsers | number: '1.0-0' }}
  </div> -->
  <footer class="footer">
    <div>
      ติดต่อสอบถามหรือแจ้งปัญหา โทร <a href="tel:024709833">02-470-9833</a> อีเมล <a
        href="mailto:pisastyle.pisacenterobec@gmail.com"
      >pisastyle.pisacenterobec@gmail.com</a>

      ไลน์ <a
        href="https://line.me/ti/p/%40pisacenterobec"
        target="_blank"
      >@pisacenterobec</a>
    </div>
    <a
      href="https://line.me/ti/p/%40pisacenterobec"
      target="_blank"
    >
      <img
        class="_add-line"
        src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png"
        alt="เพิ่มเพื่อน"
      >
    </a>
  </footer>
</section>