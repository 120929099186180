import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, forwardRef, } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

export class BaseDropdown<T> implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() name: string;
  @Input() showAll: boolean | string;
  @Input() optionList: T[];
  @Input() required: boolean;
  @Input() notEmpty: boolean;
  @Input() placeholder: string;
  @Input() compareWith: Function = this.compareFn;
  @Input() isPremitive = false;

  @Output() loaded = new EventEmitter<T[]>();

  idAttr = 'id'; // need to override compareFn manually
  textAttr = 'name';
  value: T | undefined;
  disabled: boolean;
  loading: boolean;

  private getOptionListSub: any;

  constructor() {
  }

  ngOnInit() {
    if (this.optionList) {
      this.loaded.emit(this.optionList);
    } else {
      this.getOptionList();
    }
  }

  ngOnDestroy() {
    if (this.getOptionListSub) {
      this.getOptionListSub.unsubscribe();
    }
  }

  // Override this function to change attribute for comparison
  compareFn(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }

  onSelect() {
    if (this.isPremitive) {
      this.onChange(this.value ? this.value[this.idAttr] : this.value);
    } else {
      this.onChange(this.value);
    }
  }

  reset() {
    this.value = undefined;
    this.onSelect();
  }

  writeValue(value: any): void {
    if (!this.isPremitive || value === undefined || value === null) {
      this.value = value;
    } else {
      this.value = { [this.idAttr]: value } as any; // convert to object
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private onChange = (_: any) => { };
  private onTouched = () => { };

  protected apiOptionListGet$(): Observable<T[]> {
    throw new Error('Method not implemented.');
  }

  protected getOptionList() {
    this.loading = true;
    this.getOptionListSub = this.apiOptionListGet$().pipe(
      finalize(() => this.loading = false)
    ).subscribe(data => {
      this.optionList = data;
      if (this.showAll) {
        this.addShowAllOption();
      }
      this.loaded.emit(this.optionList);
    });
  }

  protected addShowAllOption() {
    let showAllText = 'ทั้งหมด';
    if (typeof this.showAll === 'string') {
      showAllText = this.showAll;
    }
    this.optionList = [{
      [this.idAttr]: null,
      [this.textAttr]: showAllText
    } as any].concat(this.optionList);
  }

}
