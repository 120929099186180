import { Injectable } from '@angular/core';
import {
  Router, CanActivate, CanActivateChild,
  ActivatedRouteSnapshot, RouterStateSnapshot
} from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { environment } from '@app/env/environment';

@Injectable()
export class DevGuard implements CanActivate, CanActivateChild {

  constructor(
    private authService: AuthService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!environment.production) {
      return true;
    } else {
      return false;
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
}
