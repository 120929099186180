import { Component, OnInit } from '@angular/core';
import { Observable, Subscription, of, throwError } from 'rxjs';
import { AuthService } from './core/services/auth.service';
import { UsersService } from '@app/api';
import { catchError, delay, map, retryWhen } from 'rxjs/operators';
import { ApiError } from 'soft-ngx';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'pisa';

  constructor(
    private authService: AuthService,
    private usersService: UsersService
  ) {
  }

  ngOnInit(): void {
    this.postOnlineInterval();
  }

  private postOnlineInterval() {
    let req: Observable<boolean>;
    if (this.authService.isLoggedIn) {
      req = this.usersService.apiUsersOnlinePost$().pipe(
        map(() => true)
      );
    } else {
      req = of(false);
    }
    req.pipe(
      catchError((err: ApiError) => {
        if (err.ignoreGlobalErrorAlert) {
          err.ignoreGlobalErrorAlert();
        }
        return throwError(err);
      }),
      retryWhen(err$ => err$.pipe(delay(60 * 1000))),
      delay(60 * 1000),
    ).subscribe(success => {
      // call only once when logged in
      if (!success) {
        this.postOnlineInterval();
      }
    });
  }
}
