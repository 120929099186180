import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '@app/core/services/auth.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { throwError, Subscription, empty } from 'rxjs';
import { ApiError } from 'soft-ngx';
import { UsersService } from '@app/api';

@Component({
  selector: 'fe-login',
  templateUrl: './fe-login.component.html',
  styleUrls: ['./fe-login.component.scss']
})
export class FeLoginComponent implements OnInit {

  @Output() success = new EventEmitter();
  @Output() forceChangePassword = new EventEmitter<string>();

  @Output() viewRegister = new EventEmitter();
  @Output() viewForgotPassword = new EventEmitter();

  // data
  idCode: string;
  password: string;
  errorText: string;

  // subscription
  loginSub: Subscription;

  constructor(
    private authService: AuthService,
    private usersService: UsersService
  ) {
  }

  ngOnInit() {
  }

  login(form) {
    this.errorText = '';
    if (form.invalid) {
      return;
    }

    this.loginSub = this.authService.requestTokenWithPasswordFlow$(this.idCode, this.password, false).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 400) {
          this.errorText = 'เลขบัตรประชาชน หรือ รหัสผ่าน ผิด';
        }
        return throwError(err);
      }),
      mergeMap(data => {
        return this.usersService.apiUsersUserProfileGet$().pipe(
          map(profile => {
            this.authService.canAccessBooking = profile.canAccessBooking;
            this.authService.school = profile.school;
          }),
          map(() => data),
        )
      })
    ).subscribe(data => {
      if (data.needChangePassword) {
        this.authService.tempPassword = this.password;
        this.forceChangePassword.emit();
      } else {
        this.success.emit();
      }
    });
  }

  onViewRegister() {
    this.viewRegister.emit();
  }

  onViewForgotPassword() {
    this.viewForgotPassword.emit();
  }

}
