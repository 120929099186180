import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[libClickOutside]'
})
export class ClickOutsideDirective {
  @Output() libClickOutside: EventEmitter<any> = new EventEmitter();

  constructor(private _elementRef: ElementRef) {
  }

  @HostListener('document:click', ['$event'])
  public onClick($event) {
    const isClickedInside = this._elementRef.nativeElement.contains($event.target);
    if (!isClickedInside) {
      this.libClickOutside.emit($event);
    }
  }
}
