import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeaderModule } from './header/header.module';

import { EmptyLayoutComponent } from './layout/empty-layout/empty-layout.component';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HeaderModule
  ],
  declarations: [
    EmptyLayoutComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
    NavigationComponent,
    FooterComponent
  ],
  exports: []
})
export class LayoutsModule { }
