import DOMPurify from 'dompurify';

export class Utils {

  static sanitize(html: string): any {
    const sanitized = DOMPurify.sanitize(html, {
      ADD_TAGS: [
        'iframe',
        'big',
      ],
      ADD_ATTR: [
        'contenteditable', 'target', // all
        'frameborder', 'allowfullscreen', // iframe, video
        'cmd', 'key', // button
        'mathquill-block-id', 'mathquill-command-id', 'autocorrect', 'x-palm-disable-ste-all' // mathquill
      ],
      ALLOW_UNKNOWN_PROTOCOLS: true, // capacitor
    });

    // for debugging
    if (DOMPurify.removed.length > 0) {
      console.debug('DOMPurify.removed', DOMPurify.removed);
    }

    return sanitized;
  }

  static dateStartOfToday() {
    const date = new Date();
    date.setHours(0, 0, 0);
    return date;
  }

  static dateEndOfToday() {
    const date = new Date();
    date.setHours(23, 59, 59);
    return date;
  }

  static isMobile() {
    return window.matchMedia('screen and (max-width: 767px)').matches;
  }

  static isTablet() {
    return window.matchMedia('screen and (min-width: 768px) and (max-width: 1365px)').matches;
  }

  static isDesktop() {
    return window.matchMedia('screen and (min-width: 1366px)').matches;
  }

  static isIE() {
    // tslint:disable-next-line: no-string-literal
    return !!window.document['documentMode'];
  }
}
