import { Component, OnInit, Input } from '@angular/core';
import { Model } from '@app/api';

@Component({
  selector: 'lib-exam-item-result',
  templateUrl: './exam-item-result.component.html',
  styleUrls: ['./exam-item-result.component.scss']
})
export class ExamItemResultComponent implements OnInit {

  @Input() hasNoAnswer: boolean;
  @Input() score: number | null;

  constructor() { }

  ngOnInit() {
  }

}
